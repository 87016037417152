@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Red Hat Display', sans-serif;
}

.shadow-top-bar {
  box-shadow: 0 0 32px rgba(0, 0, 100, 0.1);
}
